<template>
  <div v-if="!loading">
    <v-toolbar color="#1a1a27" class="text-white rounded-0" height="80px">
      <img v-if="asideEnabled" :src="siteLogo()" alt="Logo" width="75" />
      <v-spacer v-if="asideEnabled"></v-spacer>
      <v-toolbar-title class="ml-3">
        <h2 class="mb-0">Ödemelerim</h2>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <KTTopbar></KTTopbar>
    </v-toolbar>
    <v-row
      v-if="Object.values(this.offers).length > 0"
      class="mx-4 mt-5 mt-i-5"
    >
      <v-col
        cols="12"
        md="6"
        xl="4"
        v-for="(offer, index) in offers"
        :key="index"
      >
        <v-card rounded="xl">
          <v-toolbar height="90" color="#1a1a27">
            <v-app-bar flat color="rgba(0, 0, 0, 0)">
              <img :src="siteLogo()" alt="Logo" width="100" />
              <v-divider class="ml-5 mr-5" vertical></v-divider>
              <v-spacer></v-spacer>
              <div class="d-flex flex-column justify-content-center">
                <!-- <span class="text-body-1 text-white">
                  Ürün:
                  <span style="color: red">{{
                    offer?.production?.display_name
                  }}</span></span
                >
                <span class="text-body-1 text-white"
                  >Tamamlanan Taksit:
                  <span style="color: red">{{
                    getPayedInstallmentCount(index)
                  }}</span>
                </span>
                <span class="text-body-1 text-white"
                  >Kalan Taksit:
                  <span style="color: red">{{
                    getRemainingInstallmentCount(index)
                  }}</span>
                </span>
                -->
              </div>
            </v-app-bar>
          </v-toolbar>

          <v-card-text
            style="height: 400px; overflow-y: auto"
            class="pb-3 pt-0"
          >
            <v-timeline align-top dense style="width: 21rem">
              <div class="font-weight-bold ml-4 mb-7">
                <v-chip color="#1a1a27" text-color="white" small>
                  Taksitler
                </v-chip>
              </div>
              <div v-for="(installment, index) in offer" :key="index">
                <!--
                <PaymentModal>
                  <v-btn
                    style="right: 27px; top: 25px"
                    class="mr-5"
                    elevation="0"
                    color="success"
                    small
                    v-if="!installment.payment_on"
                  >
                    Öde
                  </v-btn>
                </PaymentModal>
                -->
                <v-timeline-item
                  fill-dot
                  :icon="!installment.payment_on ? 'mdi-cash' : 'mdi-cash'"
                  :color="!installment.payment_on ? 'green' : 'green'"
                  small
                >
                  <v-row style="width: 27rem">
                    <v-col cols="3">
                      <strong
                        >{{ installment.installment_number }}. Taksit</strong
                      >
                    </v-col>
                    <v-col cols="3">
                      <strong>Tutar</strong>
                      <div class="text-caption">
                        {{ formatPrice(installment.installment_amount) }}
                      </div>
                    </v-col>
                    <!--
                    <v-col cols="3">
                      <strong>Ödeme Tarihi</strong>
                      <div class="text-caption">
                        {{
                          installment.payment_on
                            ? formatDate(installment.payment_on)
                            : "Ödenmedi"
                        }}
                      </div>
                    </v-col> 
                    -->
                    <v-col cols="3">
                      <strong>Son Ödeme Tarihi</strong>
                      <div class="text-caption">
                        {{ formatDate(installment.due_date) }}
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </div>
            </v-timeline>
          </v-card-text>
          <v-footer
            height="60px"
            color="#1a1a27"
            class="d-flex justify-content-end"
          >
            <v-btn
              elevation="10"
              color="primary"
              small
              text
              :to="{
                name: 'user-payments-view',
                params: { id: index },
              }"
            >
              <span class="text-body-1 text-white">Detay Görüntüle</span>
            </v-btn>
          </v-footer>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-sheet
        elevation="0"
        max-width="600"
        rounded="lg"
        width="100%"
        class="pa-4 text-center mx-auto mt-10"
      >
        <v-icon class="mb-5" color="info" size="112"> mdi-alert-circle </v-icon>

        <h2 class="text-h5 mb-6">
          Ödeme Bekleyen Bir İşleminiz Bulunmamaktadır.
        </h2>
      </v-sheet>
    </v-row>
  </div>
  <div
    v-else
    style="height: 250px"
    class="d-flex flex-column justify-content-end align-items-center"
  >
    <v-progress-circular
      indeterminate
      color="primary"
      :size="70"
      width="7"
    ></v-progress-circular>
    <h3 class="mt-5">Lütfen bekleyiniz...</h3>
  </div>
</template>

<script>
import objectPath from "object-path";
import { mapGetters } from "vuex";
//import PaymentModal from "@/view/content/payment/PaymentModal";
import { formatPrice } from "@/core/helper.js";
import { isEmptyObject } from "jquery";
import KTTopbar from "@/view/layout/header/Topbar.vue";

export default {
  name: "PaymentList",
  components: {
    /*PaymentModal,*/
    KTTopbar,
  },
  data() {
    return {
      headers: [
        {
          value: "actions",
          text: "Eylemler",
          width: "100px",
          align: "center",
          sortable: false,
        },
        { value: "product_name", text: "Ürün" },
        { value: "starts_on", text: "İşlem Başlangıç Tarihi" },
      ],
      loading: true,
      offers: [],
    };
  },
  created() {
    const interval = setInterval(async () => {
      if (!isEmptyObject(this.currentUser)) {
        this.offers = await this.getUserPaymentPlans(this.currentUser.id);
        this.loading = false;
        clearInterval(interval);
      }
    }, 500);
  },
  methods: {
    formatPrice,
    getUserPaymentPlans(userId) {
      return new Promise((resolve, reject) => {
        this.$apiService
          .get(`/user-payment-plans/${userId}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    formatDate(date) {
      return this.$moment(date).format("DD/MM/YYYY");
    },
    getRemainingInstallmentCount(paymentPlanId) {
      const offer = this.offers[paymentPlanId];
      const remaining = offer.filter(
        (installment) => installment.payment_on === null
      ).length;
      return remaining;
    },
    getPayedInstallmentCount(paymentPlanId) {
      const offer = this.offers[paymentPlanId];
      return offer.length - this.getRemainingInstallmentCount(paymentPlanId);
    },
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "currentUser"]),
    asideEnabled() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

<style scoped></style>
